<template>
<!-- 智慧化健身房  全市运动人群时间活跃度 -->
    <div class="sportnav darkBack">
        <div>{{area}}运动人群时间活跃度</div>
        <div id="sportline"></div>
    </div>
</template>

<script>
import {timestamp} from "/src/assets/js/websocket";

export default {
    data () {
        return {
            area:"全市",
            list:{
                xAxis:['6', '8', '10', '12', '14', '16', '18', '20', '22', '24'],
                data:[980, 1130, 1720, 2130, 2550, 2670, 3420, 2870, 2310, 1420],
            }
        }
    },
    methods: {
        lineOption(number){
            // let canye = this.$echarts.init(document.getElementById("sportline"));
            let canye = this.$echarts.getInstanceByDom(document.getElementById("sportline"))
            if (canye == null) { // 如果不存在，就进行初始化
                canye = this.$echarts.init(document.getElementById("sportline"));
            }
            var option = {
                xAxis: {
                    type: 'category',
                    axisTick: {
                        show: false
                    },
                    axisLabel: {               // x轴字体的颜色
                        textStyle: {
                            show:true,
                            fontFamily:'微软雅黑',
                            color: "#7EA7E299",
                            fontSize: '0.13rem',
                        },
                    },
                    axisLine:{              // y轴左侧的样式
                        show:true,
                        lineStyle: {
                            color: '#2D94D0',
                        }
                    },
                    data: number.xAxis
                },
                grid:{
                    left:"6%",
                    right:"8%",
                    top:"15%",
                    bottom: "10%"
                },
                yAxis: {
                    type: 'value',
                    splitNumber: 4,         // y轴左侧显示的条数
                    splitLine: {            // 网格线的颜色
                        show: true,
                        lineStyle:{
                            color: ['#2D94D040'],
                            width: 1,
                            type: 'solid',
                        }
                　　},
                    // axisLabel: {        // 隐藏y轴数据
                    //     formatter: function () {
                    //         return "";
                    //     }
                    // },
                },
                series: [{
                    data: number.data,
                    symbol: 'circle',//拐点样式
                    symbolSize: 9,   //拐点圆的大小
                    type: 'line',
                    itemStyle : { 
                        normal : { 
                            color:'#3AE6CE', //改变折线点的颜色
                            lineStyle:{ 
                                color:'#3AE6CE' //改变折线颜色
                            } 
                        } 
                    }, 
                }]
            };
            canye.setOption(option);
			// 图表自动缩放
			window.addEventListener("resize",function(){
				  canye.resize();
			});
        },
    },
    mounted () {
        let info = JSON.parse(window.localStorage.getItem("screenUserInfo"));
        if(info.is_city == 0) this.area = info.name;
        var data = {
            token: window.getToken(),
            app_id: window.appId,
            time: timestamp(),
        }

        let url = window.appHost+"/api/v1/screen/Venue/active_time"
        let result = window.ajaxRequest(url,JSON.stringify(data),"post")
        // console.log(result)
        this.list = {
                xAxis:[],
                data:[]
            };
        for(let i in result.data){
            // console.log(i,result.data[i])
            this.list.xAxis.push(i);
            this.list.data.push(result.data[i]);
        }
        this.lineOption(this.list)
    }
}
</script>

<style scoped>
.sportnav{
    width:5.05rem;
    height:3.30rem;
    color:#FFFFFF;
    padding:0.2rem;
    font-size: 0.2rem;
    position: relative;
}
#sportline{
    width:4.70rem;
    height:2.60rem;
    /* border:1px solid red; */
}
</style>